/* lib imports */
import React, { useEffect, useState } from 'react';

/* component imports */
import CrosswordCell from './CrosswordCell';

/* style */
import '../style/crossword.css';

/* represents our grid of cells */
const CrosswordGrid = ({ grid, selectedWord, selectedCell, numberedCells, onCellUpdate, handleKeyDown, onCellFocus, 
  onNextCell, blackEditable, canFocus, enforceBlack, allowDashes, isMobile }) => {

  /* describes cell size, default 60 */
  const [cellSize, setCellSize] = useState(60);

  /* upon a change to 'grid', go about setting a new size to our cells */
  useEffect(() => {

    /* helper to update cell size */
    const updateCellSize = () => {
      const containerWidth = 555 
      const maxRowLength = Math.max(...grid.map(row => row.length));
      const newCellSize = Math.floor(containerWidth / maxRowLength) - 6;
      setCellSize(newCellSize);
    };

    /* update */
    updateCellSize();
    window.addEventListener('resize', updateCellSize);
    return () => {
      window.removeEventListener('resize', updateCellSize);
    };
  }, [grid]);

  /* visual */
  return (
    <div className="grid" style={{ width: '100%' }}>
      {grid.map((row, rowIndex) => (
        <div key={rowIndex} className="row">
          {row.map((cell, colIndex) => {
            /* determine if this cell is a part of the selected word */
            const isHighlighted = selectedWord.cells && selectedWord.cells.some(c => c.x === colIndex && c.y === rowIndex);

            /* determine if this cell is numbered */
            const numberedInfo = numberedCells.find(info => info[1].x === colIndex && info[1].y === rowIndex);
            const cellNumber = numberedInfo ? numberedInfo[0] : null;

            /* visual */
            return (
              <CrosswordCell
                  key={colIndex}
                  rowIndex={rowIndex}
                  colIndex={colIndex}
                  cellNumber={cellNumber}
                  value={cell}
                  size={cellSize}
                  isSelected={selectedCell.y === rowIndex && selectedCell.x === colIndex}

                  isBlack={cell === '*'}
                  isGray={cell === '-'}
                  isHighlighted={isHighlighted}

                  onCellUpdate={(rowIndex, colIndex, newValue) => { onCellUpdate(rowIndex, colIndex, newValue); }}
                  onCellFocus={onCellFocus}
                  onNextCell={onNextCell}
                  handleKeyDown={handleKeyDown}

                  blackEditable={blackEditable}
                  canFocus={canFocus}
                  enforceBlack={enforceBlack}
                  allowDashes={allowDashes}

                  isMobile={isMobile}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default CrosswordGrid;
