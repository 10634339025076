/* lib imports */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';

/* auth context  */
import AuthContext from '../context/AuthContext';

/* style */
import '../style/auth.css';

/* register page */
const Register = () => {

  /* state variables */
  const [errors, setErrors] = useState({});
  const [tempErrorStyle, setTempErrorStyle] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { register } = useContext(AuthContext);

  /* mobile handling */
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
      window.removeEventListener('resize', handleResize);
      };
  }, []);

  /* validate inputs */
  const validateInputs = () => {
    const errors = {};
    if (validator.isEmpty(username)) {
      errors.username = 'Username is required';
    }
    if (validator.isEmpty(password)) {
      errors.password = 'password is required';
    } else {
      const passwordErrors = [];
      if (!validator.isLength(password, { min: 8 })) {
        passwordErrors.push('at least 8 characters');
      }
      if (!/[A-Z]/.test(password)) {
        passwordErrors.push('an uppercase letter');
      }
      if (!/[a-z]/.test(password)) {
        passwordErrors.push('a lowercase letter');
      }
      if (!/[0-9]/.test(password)) {
        passwordErrors.push('a number');
      }
      if (passwordErrors.length > 0) {
        errors.password = `password must include: ${passwordErrors.join(', ')}`;
      }
    }
    return errors;
  };

  /* on submission */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setTempErrorStyle(true);
      setTimeout(() => setTempErrorStyle(false), 2000);
      return;
    }
    await register(username, password);
  };

  /* Update errors as the user types */
  useEffect(() => {
    const validationErrors = validateInputs();
    setErrors(validationErrors);
  }, [password]);

  /* visual */
  return (
    <div className='reg-ult-container'>
      { isMobile ? (

            /* mobile */
            <div className='mobile-auth-container'>  

            <div className='mobile-auth-header'> New Account </div>
    
            <form onSubmit={handleSubmit}>
              
              <div className='register-input-container'>
                <input 
                  className='mobile-username-input'
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
    
                <input
                  className='mobile-register-password-input'
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
    
              {errors.password && (
                <div className={`register-error-text ${tempErrorStyle ? 'temp-error' : ''}`}>
                  {errors.password}
                </div>
              )}
              
              <button 
                type="submit"
                className='mobile-register-submit-button'>
                  Register
              </button>
            </form>
    
            <div className='mobile-register-link'>
              <Link to="/login"> Login </Link>
            </div>
    
        </div>
      ) : (
        /* desktop */
        <div className='auth-container'>  

          <div className='auth-header'> New Account </div>

          <form onSubmit={handleSubmit}>
            
            <div className='register-input-container'>
              <input 
                className='username-input'
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />

              <input
                className='password-input'
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            {errors.password && (
              <div className={`register-error-text ${tempErrorStyle ? 'temp-error' : ''}`}>
                {errors.password}
              </div>
            )}
            
            <button 
              type="submit"
              className='submit-button'>
                Register
            </button>
          </form>

          <div className='register-link'>
            <Link to="/login"> Login </Link>
          </div>

      </div>
      )}
    </div>

  );
};

export default Register;
