// console.log('REACT_APP_ROOT_URL:', process.env.REACT_APP_ROOT_URL);
// export const apiUrl = `${process.env.REACT_APP_ROOT_URL}/api`;
// export const rootUrl = process.env.REACT_APP_ROOT_URL;


/* when broken.... */
console.log('real REACT_APP_ROOT_URL:', process.env.REACT_APP_ROOT_URL);
console.log('hc REACT_APP_ROOT_URL: https://api.crosswordfight.com');
export const apiUrl = "https://api.crosswordfight.com/api";
export const rootUrl = "https://api.crosswordfight.com";
