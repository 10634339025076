/* lib imports */
import React, { useEffect, useState, useContext, useRef } from 'react';
import AuthContext from '../context/AuthContext';
import colorSets from '../config/colorSets';

/* represents a cell in our crossword */
const CrosswordCell = ({ rowIndex, colIndex, value, 
                         isBlack, isGray, isSelected, isHighlighted, cellNumber, size, onCellUpdate, onCellFocus, 
                         onNextCell, handleKeyDown, blackEditable, canFocus, enforceBlack, allowDashes, isMobile }) => {

  /* get the user */
  const { user } = useContext(AuthContext);
  const [lightColor, setLightColor] = useState(['#ccc']);
  
  /* on mount, set lightColor */
  useEffect(() => {
    const chosenPalette = colorSets[user.colorPreference];
    setLightColor(chosenPalette.light);
  }, [user.colorPreference]);

  /* Reference for the editable area */
  const editableAreaRef = useRef(null);

  useEffect(() => {
    // Function to handle focusin and focusout events
    const handleFocusIn = (e) => {
      if (e.target === editableAreaRef.current) {
        e.target.classList.add('input--focused');
      }
    };

    const handleFocusOut = (e) => {
      if (e.target === editableAreaRef.current) {
        e.target.classList.remove('input--focused');
      }
    };

    // Add event listeners to the editable area
    const editableArea = editableAreaRef.current;
    editableArea.addEventListener('focusin', handleFocusIn);
    editableArea.addEventListener('focusout', handleFocusOut);

    // Cleanup function to remove event listeners
    return () => {
      editableArea.removeEventListener('focusin', handleFocusIn);
      editableArea.removeEventListener('focusout', handleFocusOut);
    };
  }, []);

  /* style dynamically based on passed in size */
  const cellStyle = {
    width: `${size}px`,
    height: `${size}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: `${size / 3}px`,
    border: '1.5px solid #2D2D2D',
    cursor: (isBlack && !blackEditable) || !canFocus ? 'default' : 'text',
    backgroundColor: determineBackgroundColor(),   
    color: isGray ? 'white' : 'black',
    position: 'relative',
    pointerEvents: (isBlack && !blackEditable) || !canFocus ? 'none' : 'auto',
  };

  /* style for the numbers in numbered tiles */
  const numberStyle = {
    position: 'absolute',
    top: '5%', 
    left: '5%',
    fontSize: `${size / 5}px`, 
    color: '#2D2D2D', 
    pointerEvents: 'none', 
    userSelect: 'none',
  };

  /* returns color of cell */
  function determineBackgroundColor() {
    if (isGray || isBlack) return '#000';
    return isHighlighted ? lightColor : 'transparent';
  }

  /* intercept black cells and set style */
  const cellClass = `${isBlack && enforceBlack ? 'cell black' : 'cell'} ${isGray ? 'cell-gray' : ''} ${isSelected ? 'cell-selected' : ''}`;
  
  /* handle input */
  const handleInput = (e) => {
    /* return if not editing */
    if (!canFocus) { 
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    /* get user input */
    const value = e.nativeEvent.data ? e.nativeEvent.data.toUpperCase() : '';

    /* define the regex pattern based on allowDashes */
    const regexPattern = allowDashes ? /^[A-Z-]+$/ : /^[A-Z]+$/;

    /* only allow valid chars */
    if (regexPattern.test(value)) {
      e.target.innerText = value; 
      onCellUpdate(rowIndex, colIndex, value); 
      onNextCell(rowIndex, colIndex); 
    } else {
      /* revert if not valid */
      e.target.innerText = value ? value.slice(0, -1) : ''; 
    }
  };

  /* on click of a cell  */
  const handleClick = (e) => {
    /* Prevent focus if canFocus is false */
    if (!canFocus) {
      e.preventDefault();
      return;
    }
    
    /* update the focus to the new cell */
    onCellFocus(colIndex, rowIndex);

    /* prevent focus of black cells */
    if (isBlack && !blackEditable) {
      e.preventDefault();
      return;
    }
    /* select all content */
    window.getSelection().selectAllChildren(e.target);
  };

  /* visual */
  return (
    <div className={cellClass} style={cellStyle}>
      {cellNumber && <span style={numberStyle}>{cellNumber}</span>}
        <div
          ref={editableAreaRef}
          className="editable-area"
          contentEditable={!isMobile && canFocus && (!isBlack || blackEditable)} 
          onClick={handleClick}
          onInput={handleInput}
          onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
          suppressContentEditableWarning={true}
          data-row={rowIndex}
          data-col={colIndex}
          readOnly={true}
        >
        {isBlack ? '' : value}
      </div>
    </div>
  );
};

export default CrosswordCell;
