/* lib imports */
import React from 'react';

/* style */
import '../style/crossword.css';

/* clues for the crossword */
const Clues = ({ crossword, selectedWord, direction }) => {

    /* get 'across' clues */
    const acrossClues = crossword.words.filter(word => word.direction === 0)
    .sort((a, b) => a.number - b.number)
    .map((word) => (
        <li key={word.number} className={selectedWord.selectedWordNumber === word.number && direction === 0 ? 'selected-clue' : ''}>
            <div className="clue-content">
                <span className='clue-number'>{word.number}.</span>
                <span className='clue-text'>{word.hint}</span>
            </div>
        </li>
    ));

    /* get 'down' clues */
    const downClues = crossword.words.filter(word => word.direction === 1)
    .sort((a, b) => a.number - b.number)
    .map((word) => (
        <li key={word.number} className={selectedWord.selectedWordNumber === word.number && direction === 1 ? 'selected-clue' : ''}>
            <div className="clue-content">
                <span className='clue-number'>{word.number}.</span>
                <span className='clue-text'>{word.hint}</span>
            </div>
        </li>
    ));

    /* visual */
    return (
        <div className='lists-container'>
            <div>
                <div className='list-header'>ACROSS</div>
                <ul className='hint-text'>
                    {acrossClues}
                </ul>
            </div>
            <div>
                <div className='list-header'>DOWN</div>
                <ul className='hint-text'>
                    {downClues}  
                </ul>
            </div>
        </div>
    );
};

export default Clues;
