/* color options */
const colorSets = {

    /* red */
    red: {
      dark: '#95150e',  
      normal: '#fa655c',
      light: '#FFBEBB',
    },

    /* orange */
    orange: {
      dark: '#bd7a00',   
      normal: '#FFA500', 
      light: '#FFCC99', 
    },

    /* green */
    green: {
      dark: '#158926', 
      normal: '#14aa2f',
      light: '#95d79f',
    },

    /* blue */
    blue: {
      dark: '#1c7495', 
      normal: '#52B2D6',
      light: '#AEC6CF',
    },

    /* purple */
    purple: {
      dark: '#5A1A7E',   
      normal: '#a66dc7', 
      light: '#D5A3FF',  
    },

    /* pink */
    pink: {
      dark: '#e31e80',  
      normal: '#fa419d',
      light: '#f78bc0', 
    },


    /* monochromatic */
    monochromatic: {
      dark: '#333333',   
      normal: '#808080', 
      light: '#CCCCCC', 
    },



  };
  
  export default colorSets;
  