import React, { useState, useEffect } from 'react';

/* url's */
import { apiUrl, rootUrl } from '../config/environment';

/* comments */
const Comments = ({ puzzleId, user }) => {
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  /* delete comment */
  const handleDelete = async (commentId) => {
    try {
      const response = await fetch(`${apiUrl}/puzzles/${puzzleId}/comments/${commentId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      if (!response.ok) throw new Error('Failed to delete comment.');
      fetchComments(); // Refresh comments after deletion
    } catch (error) {
      console.error('Error deleting comment:', error);
      setErrorMessage('Failed to delete comment. Please try again later.');
    }
  };

  /* fetch comment */
  const fetchComments = async () => {
    try {
      const response = await fetch(`${apiUrl}/puzzles/${puzzleId}/comments`);
      if (!response.ok) throw new Error('Failed to fetch comments.');
      const data = await response.json();
      setComments(data);
    } catch (error) {
      console.error('Error fetching comments:', error);
      setErrorMessage('Failed to fetch comments. Please try again later.');
    }
  };

  /* on new puzzle to render */
  useEffect(() => {
    fetchComments();
  }, [puzzleId]);

  /* on submit of a comment */
  const handleSubmit = async (e) => {
    e.preventDefault();

    /* validation */
    const trimmedComment = newComment.trim();
    if (trimmedComment.length > 200) {
      setErrorMessage("Comment cannot exceed 200 characters.");
      return;
    }
  
    try {
      const response = await fetch(`${apiUrl}/puzzles/${puzzleId}/add-comment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({ user: user._id, comment: newComment, username: user.username})
      });
      if (!response.ok) throw new Error('Failed to add comment.');
      const addedComment = await response.json();
      setComments(prev => [...prev, {
        _id: addedComment._id,
        user: { _id: user._id, username: user.username },
        date: new Date().toISOString(),
        comment: newComment
      }]);
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
      setErrorMessage('Failed to submit comment. Please try again later.');
    }
  };

  return (
    <div className='ultimate-comment-container'>

        <div className='comment-header'>COMMENTS</div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <div className={`comment-container ${comments.length > 0 ? 'comment-container-bordered' : 'comment-container-empty'}`}>
          <ul className="comments-list">
            {comments.map(comment => (

              <li key={comment._id}>
                
                <div className='comment-details-container'>
                  <div className='comment-inner-header-and-content'>
                    <div className='username-date'>
                      <strong>{comment.user.username}</strong> - {new Date(comment.date).toLocaleString()}
                    </div>
                    <p>{comment.comment}</p>
                  </div>

                  {user._id === comment.user._id && (
                      <button onClick={() => handleDelete(comment._id)} className="delete-button">
                        <i className='bx bxs-trash-alt'></i>
                      </button>
                  )}
                </div>

              </li>

            ))}
          </ul>
        </div>

        <form onSubmit={handleSubmit}>
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Add your comment..."
              maxLength="200"
              required
            />
            <button className='submit' type="submit">Submit Comment</button>
        </form>

    </div>
  );
};

export default Comments;