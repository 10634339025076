import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/success-popup.css';

const SuccessPopup = ({ onClose }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/');
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Congratulations!</h2>
        <p>your puzzle was submitted successfully</p>
        <button className="sp-close-button" onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default SuccessPopup;
