/* lib imports */
import React from 'react';

/* represents a cell in our crossword */
const CrosswordCell = ({ value, isBlack }) => {

  /* intercept black cells and set style */
  const cellClass = isBlack ? 'cell black' : 'cell';

  /* visual */
  return (
    <div className={cellClass} >
        <div className="editable-area">
            {isBlack ? '' : value}
        </div>
    </div>
  );
};

export default CrosswordCell;