/* lib imports */
import React, { useContext, useState, useEffect } from 'react';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

/* url's */
import { apiUrl, rootUrl } from '../config/environment';

/* components */
import VisualCrosswordGrid from '../components/VisualCrosswordGrid.js'; 

/* style */
import '../style/profile.css';
import colorSets from '../config/colorSets.js'; 


/* profile page */
function Profile() {

  /* create history object for navigation */
  const navigate = useNavigate(); 

  /* get the user */
  const { user, updateUserColorPreference } = useContext(AuthContext);

  /* on mount, update selected color */
  useEffect(() => {
    setSelectedColor(user.colorPreference);
  }, []);

  /* update style based on color preference */
  useEffect(() => {
    if (user && user.colorPreference) {
      const initialIndex = colorNames.indexOf(user.colorPreference);
      if (initialIndex !== -1) {
        setCurrentColorIndex(initialIndex);
      }
    }
    const chosenPalette = colorSets[user.colorPreference]; /* name of color set */
    if (chosenPalette) {
      document.documentElement.style.setProperty('--color-dark', chosenPalette.dark);
      document.documentElement.style.setProperty('--color-normal', chosenPalette.normal);
      document.documentElement.style.setProperty('--color-light', chosenPalette.light);
    }
  }, [user.colorPreference]);

  /* mobile handling */
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
      window.removeEventListener('resize', handleResize);
      };
  }, []);

  /* state for puzzles */
  const [puzzles, setPuzzles] = useState([]);
  const [loadingPuzzles, setLoadingPuzzles] = useState(true);

  /* profile color select */
  const [selectedColor, setSelectedColor] = useState('');

  /* handle new color selection */
  const handleColorSelect = async (colorName, colors) => {

    /* update local */
    setSelectedColor(colorName);

    /* hit our endpoint and update the user */
    try {
      const response = await fetch(`${apiUrl}/user/update-color-set`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({
          colorSet: {
            name: colorName,
          }
        })
      });
      const data = await response.json();
      if (response.ok) {
        updateUserColorPreference(colorName);
      } else {
        throw new Error(data.message || 'Failed to update color set');
      }
    } catch (error) {
      console.error('Error updating color set:', error);
    }
  };

  // Color slider state
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const colorNames = Object.keys(colorSets);
  const currentColorName = colorNames[currentColorIndex];
  const currentColor = colorSets[currentColorName];

  // Handle left arrow click
  const handlePreviousColor = () => {
    setCurrentColorIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? colorNames.length - 1 : prevIndex - 1;
      // Automatically select the new color
      handleColorSelect(colorNames[newIndex], colorSets[colorNames[newIndex]]);
      return newIndex;
    });
  };

  // Handle right arrow click
  const handleNextColor = () => {
    setCurrentColorIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % colorNames.length;
      // Automatically select the new color
      handleColorSelect(colorNames[newIndex], colorSets[colorNames[newIndex]]);
      return newIndex;
    });
  };

  // Update the selected color
  const handleColorChange = () => {
    handleColorSelect(currentColorName, currentColor);
  };

  /* fetch details of the puzzles the user has made */
  useEffect(() => {
    const fetchPuzzles = async () => {
      if (user && user.puzzlesCreated.length > 0) {
        try {
          const response = await fetch(`${rootUrl}/puzzles/details`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: JSON.stringify({ puzzleIds: user.puzzlesCreated }),
          });

          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }

          const puzzleDetails = await response.json();
          setPuzzles(puzzleDetails);
        } catch (error) {
          console.error('Error fetching puzzles:', error);
        } finally {
          setLoadingPuzzles(false);
        }
      } else {
        setLoadingPuzzles(false);
      }
    };

    fetchPuzzles();
  }, [user]);

  /* visual */
  if (isMobile) {
    /* mobile layout */
    return (
      <div className='mobile-profile-container'>

        <div className='mobile-profile-header'>
            <button className='mobile-profile-back-button' onClick={() => navigate('/')}>
              <i className='bx bxs-left-arrow'></i>
            </button>           
            <h1>PROFILE</h1>
            <button className='mobile-profile-button' onClick={() => navigate('/play/random')}>
                <i className='bx bxs-dice-6'></i>
            </button>
        </div>

        <div className='mobile-traits-and-color'>

          <div className="mobile-profile-details">
            <div className="profile-item">
              <strong>Username</strong> <span>{user.username}</span>
            </div>
            <div className="profile-item">
              <strong>Skill Rating</strong> <span>{user.skillRating}</span>
            </div>
            <div className="profile-item">
              <strong>Puzzles Created</strong> <span>{user.puzzlesCreated.length}</span>
            </div>
          </div>

          {/* Slider for color selection */}
          <div className="color-slider">
              <button className="arrow-button" onClick={handlePreviousColor}>
                <i className='bx bxs-left-arrow'></i>
              </button>

              <div
                className="current-color-box"
                style={{
                  backgroundColor: currentColor.light,
                  width: '100px',
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'center',
                  border: '3px solid black',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
                onClick={handleColorChange}
                onMouseEnter={e => e.currentTarget.style.backgroundColor = currentColor.normal}
                onMouseLeave={e => e.currentTarget.style.backgroundColor = currentColor.light}
              >
              </div>

              <button className="arrow-button" onClick={handleNextColor}>
                <i className='bx bxs-right-arrow'></i>
              </button>
          </div>

        </div>

        <div className='mobile-puzzle-details'>
          <div className='mobile-trait-header'>Your Puzzles</div>
          <div className="mobile-puzzles-list">
            {loadingPuzzles ? (
              <p>Loading puzzles...</p>
            ) : (
              puzzles.length > 0 ? (
                <ul>
                  {puzzles.map(puzzle => (
                    <li key={puzzle._id}>
                      <strong>{puzzle.title}</strong>
                      <VisualCrosswordGrid grid={puzzle.grid} />
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="mobile-no-puzzle-header">You have yet to begin your collection!</div>
              )
            )}
          </div>
        </div>

      </div>
      
    );
  } else {
      return (
        <div className="profile-container">
          {/* Desktop layout */}
          <div className='back-profile-header'>
            <button className='back-button' onClick={() => navigate('/')}>
              <i className='bx bxs-left-arrow'></i>
            </button>
            <h1>PROFILE</h1>

            <div className="color-container">
              {Object.entries(colorSets).map(([setName, colors]) => (
                <div
                  key={setName}
                  className={`color-set-cell`}
                  style={{
                    backgroundColor: colors.light,
                    padding: '10px',
                    margin: '5px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                    border: selectedColor === setName ? '3px solid black' : 'none',
                  }}
                  onMouseEnter={e => e.currentTarget.style.backgroundColor = colors.normal}
                  onMouseLeave={e => e.currentTarget.style.backgroundColor = colors.light}
                  onClick={() => handleColorSelect(setName, colors)}
                />
              ))}
            </div>
          </div>

          {user ? (
            <>
              <div className="profile-details">
                <div className="profile-item">
                  <strong>Username:</strong> <span>{user.username}</span>
                </div>
                <div className="profile-item">
                  <strong>Skill Rating:</strong> <span>{user.skillRating}</span>
                </div>
                <div className="profile-item">
                  <strong>Puzzles Created:</strong> <span>{user.puzzlesCreated.length}</span>
                </div>
              </div>

              <div className='puzzle-details'>
                <div className='trait-header'>Your Puzzles</div>
                <div className="puzzles-list">
                  {loadingPuzzles ? (
                    <p>Loading puzzles...</p>
                  ) : (
                    puzzles.length > 0 ? (
                      <ul>
                        {puzzles.map(puzzle => (
                          <li key={puzzle._id}>
                            <strong>{puzzle.title}</strong>
                            <VisualCrosswordGrid grid={puzzle.grid} />
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="no-puzzle-header">You have yet to begin your collection!</div>
                    )
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="loading-container" style={{ flex: 1 }}>
              <div className="loading-message">
                Loading crossword...
              </div>
            </div>
          )}
        </div>
      );
    }
}

export default Profile;
