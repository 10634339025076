/* style */
import '../style/alert.css';

/* screen notifications to user */
const Alert = ({ message, isMobile }) => {
    return (
        <div className={isMobile ? 'mobile-alert-container' : 'alert-container'}>
          <p>{message}</p>
        </div>
    );
};

export default Alert;
