import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './app/src/context/AuthContext';
import './app/src/style/index.css';
import App from './app/src/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <AuthProvider> 
            <App/>
        </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);