const Rating = ({ count, value, onRating }) => {
  return (
    <div>
      {[...Array(count)].map((_, i) => (
        <span key={i} onClick={() => onRating(i + 1)}
          style={{
            display: 'inline-block',
            width: '20px', 
            height: '20px',
            backgroundColor: value >= i + 1 ? 'black' : 'transparent', 
            border: '1px solid black',
            cursor: 'pointer',
            marginRight: 5,
            padding: 0
          }}>
        </span>
      ))}
    </div>
  );
};

export default Rating;