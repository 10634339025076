/* lib imports */
import React from 'react';

/* component imports */
import VisualCrosswordCell from './VisualCrosswordCell';

/* style */
import '../style/crossword.css';
import '../style/profile.css';

/* represents our grid of cells */
const CrosswordGrid = ({ grid }) => {

  /* visual */
  return (
    <div className="profile-grid" style={{ width: '100%' }}>
      {grid.map((row, rowIndex) => (
        <div key={rowIndex} className="profile-row">
          {row.map((cell, colIndex) => {

            /* visual */
            return (
              <VisualCrosswordCell
                  key={colIndex}
                  value={cell}
                  isBlack={cell === '*'}
              />
            );

          })}
        </div>
      ))}
    </div>
  );
};

export default CrosswordGrid;
